export default {
  heading: 'EB Garamond, serif',
  body: 'Raleway, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'EB Garamond, serif',
  googleFonts: ['Vollkorn', 'Raleway', 'EB Garamond'],
  // customFamilies: ['Bromello Regular'],
  // customUrls: [
  //   'https://gonation.biz/fonts/bromello-regular/bromello-regular.css',
  // ],
}

export default {
  sideBySideV1: {
    container: {
      backgroundColor: 'background',
      flexDirection: ['column', '', 'row'],
      alignItems: ['', '', 'stretch'],
      padding: '1rem',
      height: ['', '', '50vh'],
      '.lazyload-wrapper': {
        width: ['100%', '', '50%'],
      },
    },
    content: {
      flexDirection: 'column',
      padding: ['1rem 1rem 2rem', '', '', '2rem'],
      width: ['100%', '', '50%'],
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        display: 'flex',
        width: 'fit-content',
      },
    },
    subtitle: {
      fontWeight: 6,
      marginBottom: 3,
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: 3,
      borderBottom: '2px solid',
      borderColor: 'primary',
      paddingBottom: '1rem',
    },

    ctaButton: {
      padding: 3,
      marginTop: 5,
      border: 'solid 2px',
      borderColor: 'primary',
      color: 'primary',
      width: 'max-content',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'light',
      },
    },

    text: {
      lineHeight: '1.75',
      whiteSpace: 'break-spaces',
    },
    image: {
      width: ['100%'],
      height: '100%',
      objectFit: 'cover',
    },
  },
}
